import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql, Link } from 'gatsby'

import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import PageHeading from '../components/Common/PageHeading'
import Footer from '../components/_App/Footer'
import { dayFormat, monthFormat, yearFormat } from '../helper/dateFormat'

const ServiceDetailsTemplate = ({ data }) => {
  const { detailsText, image, title } = data.service

  return (
    <Layout>
      <Seo
        title={`${title} - IT Services`}
        description={detailsText.substring(0, 200)}
        image={image.url}
      />

      <TopHeader />

      <Navbar />

      <PageHeading pageTitle={title} />

      <div className="service-details-area pt-70 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src={image.url} alt="Service" />
                </div>
                <div className="service-article-content">
                  <ReactMarkdown>{detailsText}</ReactMarkdown>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="side-bar-area">
                <div className="side-bar-widget">
                  <h3 className="title">Other IT Services</h3>
                  <div className="side-bar-categories">
                    <ul>
                      {data.services.nodes.map(service => {
                        return (
                          <li key={service.id}>
                            <div className="line-circle" />
                            <Link to={`/services/${service.slug}`}>{service.title}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

                <div className="side-bar-widget">
                  <h3 className="title">Employee Blog's</h3>
                  <div className="widget-popular-post">
                    {data.blogs.nodes.map((blog, index) => {
                      return (
                        <article className="item" key={index}>
                          <Link to={`/blogs/${blog.slug}`} className="thumb">
                            <span className="full-image cover">
                              <img src={blog.blogImg.url} alt={blog.title} height="100%" />
                            </span>
                          </Link>
                          <div className="info">
                            <h4 className="title-text">
                              <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                            </h4>
                            <p>
                              {dayFormat(blog.publishedOn)} {monthFormat(blog.publishedOn)},{' '}
                              {yearFormat(blog.publishedOn)}
                            </p>
                          </div>
                        </article>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleServiceDetails($slug: String) {
    service: strapiServices(slug: { eq: $slug }) {
      detailsText
      title
      image {
        url
      }
    }
    services: allStrapiServices(filter: { slug: { ne: $slug } }) {
      nodes {
        title
        slug
        id
      }
    }
    blogs: allStrapiBlog(limit: 5, sort: { fields: publishedOn, order: DESC }) {
      nodes {
        publishedOn
        slug
        title
        updated_at
        blogImg {
          url
        }
      }
    }
  }
`

export default ServiceDetailsTemplate
